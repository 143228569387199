import React from 'react';
import { AppState } from '../state/store';
import { useAppSelector, useAppDispatch } from 'state/hooks';
import {
    changeName,
    init,
    login,
    logout,
    setTvSubscriptions,
    setUserFlags,
    updateRoomJoinsCount,
    updateUserFlags,
    userDataFetched,
} from 'packages/common/state/user';
import { RequestUserPOST, userGET, userPOST, userUsageGET } from 'packages/common/api-client/user';
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getMagicPubKey } from '../utils/auth';
import { API_HOST } from '../typescript/api';
import { User } from 'packages/common/api-client/types';
import { dismissModal, track, trackingQueuePop } from '../state/app';
import { identifyUser } from '../state/app';
import Cookie from 'js-cookie';
import { PlaybackCookie, getAuthCookieOptions } from '../utils/cookies';
import { getData, postData } from '../utils/api';
import { UserFlag, UserFlags } from 'packages/common/base/types';
import { tvAuthSubscriptionsGET } from 'packages/common/api-client/tvAuth';
import { getAnonymousID } from '../state/middleware/tracking';
import { setEnabledTvAuthPlatforms } from 'packages/common/state/accountPanel';

export interface ApiAuthOptions {
    redirect?: string;
}

export interface IAuthContext {
    user: AppState['user'];
    loggedIn: boolean;
    login: (loginData: User, accessToken: string) => void;
    logout: () => Promise<void>;
    changeUserName: (name: string) => Promise<void>;
    withApiAuth: <T>(request: Promise<T>, options?: ApiAuthOptions) => Promise<T>;
    setFlag: (flag: UserFlag, value: any) => Promise<void>;
}

export const AuthContext = React.createContext({} as IAuthContext);

const AuthProvider: React.FunctionComponent<{}> = (props) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const user = useAppSelector((state) => state.user);
    const trackingQueue = useAppSelector((state) => state.app.trackingQueue);
    const loggedIn = !!user.accessToken;

    const setAuthCookie = useCallback((accessToken: string) => {
        Cookie.set(PlaybackCookie.AccessToken, accessToken, getAuthCookieOptions());
    }, []);

    const dispatchLogout = useCallback(async () => {
        const Magic = await import('packages/common/auth/magic/magic');
        await Magic.logout(getMagicPubKey());

        Cookie.remove(PlaybackCookie.AccessToken);
        dispatch(track({ event: 'Logout' }));
        dispatch(logout());
        dispatch(dismissModal());

        const Sentry = await import('@sentry/nextjs');
        Sentry.configureScope((scope) => scope.setUser(null));
    }, [dispatch, user.email]);

    const withApiAuth = useCallback(
        async function withApiAuth<T>(request: Promise<T>, options: ApiAuthOptions = {}) {
            const { redirect = '/' } = options;

            try {
                return await request;
            } catch (e) {
                if (e.response.status >= 400 && e.response.status < 500) {
                    await dispatchLogout();

                    if (redirect) {
                        router.push(redirect);
                    }
                }

                throw e;
            }
        },
        [dispatchLogout, router]
    );

    useEffect(() => {
        const initializeUser = async () => {
            let apiUser: User = null;
            if (user.accessToken) {
                try {
                    apiUser = await withApiAuth(userGET(user.accessToken, API_HOST));
                } catch {
                    dispatch(track({ event: 'User Init Token Rejected' }));
                }
            }

            dispatch(init(apiUser));
            dispatch(identifyUser({}));
        };

        initializeUser();
    }, [user.accessToken, dispatch, withApiAuth]);

    useEffect(() => {
        if (user.id && user.accessToken) {
            const fetchAdditionalData = async () => {
                const usageRequest = userUsageGET(user.accessToken, API_HOST);
                const tvSubscriptionsRequest = tvAuthSubscriptionsGET(user.accessToken, API_HOST);
                const tvPlatformsRequest = getData<UserFlags>(`/api/tvAuth/providers`);
                const flagsRequest = getData<UserFlags>(`/api/user/${user.id}`, user.accessToken);
                const [usage, tvSubscriptions, tvPlatforms, flags] = await Promise.all([
                    usageRequest,
                    tvSubscriptionsRequest,
                    tvPlatformsRequest,
                    flagsRequest,
                ]);

                dispatch(setUserFlags(flags));
                dispatch(setTvSubscriptions(tvSubscriptions));
                dispatch(setEnabledTvAuthPlatforms(tvPlatforms));
                dispatch(updateRoomJoinsCount(usage.roomJoinsCount));
                dispatch(userDataFetched());
            };

            fetchAdditionalData();
        }
    }, [user.accessToken, user.id, dispatch]);

    useEffect(() => {
        const initSentryUser = async () => {
            const Sentry = await import('@sentry/nextjs');
            Sentry.setUser({
                id: user.id || getAnonymousID(),
                username: user.name,
                email: user.email,
            });
        };

        if (user.id) {
            initSentryUser();
        }
    }, [user.id, user.name, user.email]);

    // Process tracking actions queued while user was
    // initializing
    useEffect(() => {
        if (user.initialized && trackingQueue.length > 0) {
            const queuedAction = trackingQueue[0];
            dispatch(queuedAction);
            dispatch(trackingQueuePop());
        }
    }, [user.initialized, trackingQueue, dispatch]);

    const dispatchLogin = useCallback(
        (user: User, accessToken: string) => {
            setAuthCookie(accessToken);
            dispatch(login({ user, accessToken }));
        },
        [dispatch, setAuthCookie]
    );

    const changeUserName = useCallback(
        async (name) => {
            dispatch(
                track({
                    event: 'Change Username',
                    updatedName: name,
                })
            );
            dispatch(changeName(name));

            const userPOSTReq: RequestUserPOST = {
                name: name,
                accessToken: user.accessToken,
            };

            try {
                const user = await withApiAuth(userPOST(userPOSTReq, API_HOST));
                dispatch(init(user));
            } catch (e) {
                console.error('failed to update user', e);
            }
        },
        [dispatch, withApiAuth, user.name, user.accessToken]
    );

    const setFlag = useCallback(
        async (flag: UserFlag, value: any) => {
            const flags = await postData<UserFlags>(
                `/api/user/${user.id}`,
                {
                    [flag]: value,
                },
                user.accessToken
            );

            dispatch(updateUserFlags(flags));
        },
        [dispatch, user.id, user.accessToken]
    );

    const auth = {
        user,
        loggedIn,
        login: dispatchLogin,
        logout: dispatchLogout,
        changeUserName,
        withApiAuth,
        setFlag,
    };

    return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
